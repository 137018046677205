<template>
<div>
  <h1>Not Found!</h1>
  <p> The page you are looking is not found. Make sure you entered correct URL. </p>
</div>
</template>

<script>
export default {
  name: "NotFoundView"
}
</script>

<style scoped>

</style>